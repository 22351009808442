<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white">
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ $language.common.notify }}</h2>
						<span class="page_subtit">{{ item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-70">
			<div class="subindex_wrap">
				<!-- 새글 피드 알림 항목 -->
				<div class="subindex_head">
					<div class="container">
						<div class="subindex_box_1">
							<span class="cell_group_tit">{{ $language.notify.message_01 }}</span>
							<ul class="settings_list">
								<li class="settings_list_item">
									<div class="cell_area">
										<div class="cell_tit">{{ $language.notify.notify_notice }}</div>
										<div class="cell_action" @click="postCartelPush('is_notice')">
											<p class="toggle-group">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span class="btn-toggle" :class="{ on: item_cartel.is_notice }"><em class="hide">{{ $language.notify.notify_notice }}</em></span>
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- //새글 피드 알림 항목 -->
				<!-- 내 소식 알림 항목 -->
				<div class="subindex_item">
					<div class="container">
						<div class="subindex_box_1">
							<span class="cell_group_tit">{{ $language.notify.message_02 }}</span>
							<ul class="settings_list">
								<li class="settings_list_item">
									<div class="cell_area">
										<div class="cell_tit">{{ $language.notify.notify_comment }}</div>
										<div class="cell_action" @click="postCartelPushComment('is_comment')">
											<p class="toggle-group">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span class="btn-toggle " :class="{ on: item_cartel.is_comment }"><em class="hide">{{ $language.notify.notify_comment }}</em></span>
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- //내 소식 알림 항목 -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia076'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.notify.notify_cartel_set
					, title: this.$language.notify.notify_set
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
				}
				, item_cartel: {
					is_notice: true
					, is_comment: false
				}
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						if(this.item_cartel.comment_note_fg == 'Y'){
							this.item_cartel.is_comment = true
						}else{
							this.item_cartel.is_comment = false
						}
						if(this.item_cartel.notice_note_fg == 'Y'){
							this.item_cartel.is_notice = true
						}else{
							this.item_cartel.is_notice = false
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCartelPushComment: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_comment_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, comment_note_fg: this.item_cartel.is_comment = !this.item_cartel.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCartelPush: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_notice_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_note_fg: this.item_cartel.is_notice = !this.item_cartel.is_notice ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>